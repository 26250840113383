<template>
  <div class="main-page" @click="isUser = false">
    <div class="header">
      <div class="logo"></div>
      <div class="tools">
        <div class="search-box">
          <div class="search-icon"></div>
          <input
            v-model.trim="keywords"
            type="text"
            class="input-box"
            placeholder="输入病历号/姓名/手机号搜索患者"
            @keyup="handleSearchPatient"
          />
        </div>
        <div class="search-result" v-if="isShow">
          <template v-if="loading">
            <el-skeleton animated :style="{ margin: '16px 0' }" />
          </template>
          <template v-else>
            <template v-if="searchArray.length > 0">
              <template v-for="(item, index) in searchArray">
                <div class="item-box" :key="index" v-if="20 >= index">
                  <img :src="item.headpicture" class="box-avatar" alt="" />
                  <div class="info">
                    <div class="nick">{{ item.patient_name }}</div>
                    <div class="no">{{ item.privateId }}</div>
                    <div class="birthday">
                      {{ item.physical_age }}（{{ item.birth }}）
                    </div>
                    <div class="doctor">
                      责任医生：{{ item.duty_doctor_name }}
                    </div>
                    <div class="bar">
                      <div class="text">末诊：</div>
                      <div class="text">{{ item.last_show }}</div>
                    </div>
                  </div>
                  <div class="btn-panel">
                    <div @click="LinkTo(item.patient_id || '')" class="btn">
                      患者360
                    </div>
                    <!-- <router-link :to="`/odos?patientid=${item.patient_id || ''}&appid=0`" @click="keywords = ''" class="btn">患者360</router-link> -->
                    <div class="btn disabled el-icon-lock">口腔检查表</div>
                    <div class="btn disabled el-icon-lock">智能内窥镜</div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <!-- <el-button
            type="text"
            icon="el-icon-refresh"
            class="refresh"
            @click="handleClearCache"
            >清空缓存</el-button
          > -->
        <!-- <el-button type="text" icon="el-icon-help" class="help"
            >帮助</el-button
          > -->
        <el-select
          placeholder="选择门店"
          @focus="officeClick"
          :value="officeId"
          @change="handleChangeStore"
          class="office"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="(item, index) in storeArray"
            :key="index"
            :value="item.id"
            :label="item.abbreviation"
          ></el-option>
        </el-select>
        <button class="call-btn" v-if="phoneFlag == 1" @click="toPhone">
          <div class="icon"></div>
        </button>
        <span
          class="user"
          :class="{ userBox: isUser }"
          @click.stop="isUser = !isUser"
        >
          <img
            src="../assets/femaleImg.svg"
            class="avatar"
            v-if="user?.sex && user?.sex == 0"
          />
          <img
            src="../assets/maleImg.svg"
            class="avatar"
            v-else-if="user?.sex && user?.sex == 1"
          />
          <img src="../assets/unknown.svg" class="avatar" v-else />
          <span class="nick">{{ user?.userName }}</span>

          <span class="el-icon-caret-bottom" :class="{ trans: isUser }"></span>
        </span>
        <div class="userCenter" v-if="isUser">
          <div class="userItem" @click="handleLogout">
            <img src="@/assets/logout.svg" alt="" />
            <span>退出登录</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <el-aside class="aside" width="auto">
        <el-menu
          :default-active="$route.meta.high ? $route.meta.high : $route.path"
          router
          :collapse-transition="false"
          :collapse="isCollapse"
        >
          <template v-for="(item, index) in menu">
            <template v-if="!item.hidden">
              <template v-if="item.leaf">
                <el-submenu :index="item.path" :key="index">
                  <template slot="title">
                    <i class="icon" :class="item.icon"></i>
                    <span class="name">{{ item.name }}</span>
                  </template>
                  <template v-for="(item, index) in item.children">
                    <template v-if="!item.hidden">
                      <el-menu-item
                        style="padding-left: 40px !important"
                        :class="{ active: isCollapse }"
                        :index="item.path"
                        :key="index"
                      >
                        {{ item.name }}
                      </el-menu-item>
                    </template>
                  </template>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item
                  :class="{ active: isCollapse }"
                  :index="item.path"
                  :key="index"
                >
                  <i class="icon" :class="item.icon"></i>
                  <span class="name">{{ item.name }}</span>
                </el-menu-item>
              </template>
            </template>
          </template>
        </el-menu>
        <div
          class="collapse"
          :class="{ expend: isCollapse }"
          @click="isCollapse = !isCollapse"
        >
          <img src="@/assets/expend.svg" />
        </div>
      </el-aside>
      <div class="app-container">
        <router-view />
      </div>
    </div>

    <el-dialog
      title="新建患者"
      :visible.sync="show"
      width="480px"
      @close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="form.sex" :style="{ width: '100%' }">
            <el-option value="0" label="女"></el-option>
            <el-option value="1" label="男"></el-option>
            <el-option value="2" label="未知"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日" prop="birth">
          <el-date-picker
            v-model="form.birth"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :style="{ width: '100%' }"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="form.nickName"></el-input>
        </el-form-item>
        <el-form-item label="所属医生" prop="doctorId">
          <el-select
            v-model="form.doctorId"
            :style="{ width: '100%' }"
            clearable
          >
            <el-option
              v-for="(item, index) in doctorArray"
              :key="index"
              :label="item?.name"
              :value="item?.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资源" prop="source">
          <el-input v-model="form.source"></el-input>
        </el-form-item>
        <el-form-item :style="{ marginBottom: '0px' }">
          <el-button type="primary" :loading="loading" @click="handleSave"
            >确定</el-button
          >
          <el-button @click="handleClose">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  DoctorList,
  GetRouters,
  GetStoreList,
  LoginOffice,
  SavePatient,
  SearchPatient,
} from "@/services/api";
import { clearToken, getToken } from "@/hooks/cookies";
import { reqGetLastCnoByUserId, reqCellLogout } from "@/services/phone";
import { Message } from "element-ui";
export default {
  name: "MainView",
  data() {
    return {
      phoneFlag: sessionStorage.phoneFlag,
      user: null,
      menu: [],
      storeArray: [],
      officeId: 0,
      isCollapse: false,
      isUser: false,
      keywords: "",
      isShow: false,
      loading: false,
      searchArray: [],
      show: false,
      form: {
        name: "",
        sex: "1",
        birth: "",
        mobile: "",
        nickName: "",
        doctorId: "",
        source: "",
      },
      doctorArray: [],
      rules: {
        name: [{ required: true, message: "请输入患者姓名" }],
        birth: [{ required: true, message: "请选择生日" }],
        sex: [{ required: false }],
        mobile: [{ required: false }],
        nickName: [{ required: false }],
        doctorId: [{ required: false }],
        source: [{ required: false }],
      },
      current: "",
    };
  },
  mounted() {
    const qno = sessionStorage.qno;
    this.user = JSON.parse(sessionStorage.jc_odos_user);
    if (qno) {
      this.user.qno = qno;
    }
    const menu = sessionStorage.care_router || null;
    if (!menu) {
      this.init();
      console.log("a");
    } else {
      console.log("b");
      this.menu = JSON.parse(menu);
      GetStoreList().then(({ rows }) => {
        this.user.qno = rows.filter((i) => i.id == this.user.officeId)[0].qno;
        sessionStorage.qno = this.user.qno;
        this.storeArray = rows;
      });
    }
    // 登录之后的officeID
    this.officeId = this.user.officeId;
    this.initDoctor();
  },
  methods: {
    toPhone() {
      const user = JSON.parse(sessionStorage.jc_odos_user);
      const userId = sessionStorage.jc_operatorId;
      const userName = sessionStorage.jcUserName;
      const token = getToken();
      const query = `qno=${this.user.qno}&officeId=${user.officeId}&userId=${userId}&userName=${userName}&token=${token}`;
      if (process.env.VUE_APP_MODE === "development") {
        window.open(`https://devsmartphone.aiorange.com:30001/open?${query}`);
      } else if (process.env.VUE_APP_MODE === "test") {
        window.open(`https://testsmartphone.aiorange.com/open?${query}`);
      } else {
        window.open(`https://smartphone.aiorange.com/open?${query}`);
      }
    },
    officeClick() {
      this.isUser = false;
    },
    init() {
      GetRouters().then(({ data }) => {
        this.menu = this.$router.options.routes.map((item) => {
          const index = data.findIndex((a) => a.meta.title === item.name);
          if (index == -1) {
            return item;
          } else if (item.leaf) {
            return {
              ...item,
              hidden: data[index]?.hidden,
              children: item.children.map((i) => {
                const idx = data[index].children?.findIndex(
                  (a) => a.meta.title === i.name
                );
                if (idx == -1 || !data[index].children) {
                  return i;
                }
                return {
                  ...i,
                  hidden: data[index].children[idx].hidden,
                };
              }),
            };
          }
          return {
            ...item,
            hidden: data[index]?.hidden,
          };
        });
        sessionStorage.care_router = JSON.stringify(this.menu);
        GetStoreList().then(({ rows }) => {
          this.user.qno = rows.filter((i) => i.id == this.user.officeId)[0].qno;
          sessionStorage.qno = this.user.qno;
          this.storeArray = rows;
        });
      });
    },

    initDoctor() {
      DoctorList({ doctor: "医生" }).then(({ rows }) => {
        this.doctorArray = rows;
      });
    },

    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          SavePatient(this.form).then(() => {
            this.loading = false;
            this.show = false;
            this.$message.success("患者新建成功");
            if (this.$route?.name === "患者中心") {
              location.reload();
            } else {
              this.$router.push("/files/patient");
            }
          });
          SavePatient(this.form)
            .then(() => {
              this.loading = false;
              this.show = false;
              this.$message.success("患者新建成功");
              if (this.$route?.name === "患者中心") {
                location.reload();
              } else {
                this.$router.push("/files/patient");
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error(err);
            });
        }
      });
    },

    handleClose() {
      this.show = false;
      this.$refs.form.resetFields();
    },

    // 退出登录
    handleLogout() {
      const that = this;
      this.$confirm("确定要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clearToken();
          sessionStorage.clear();
          sessionStorage.setItem("isLogout", "true");
          that.$router.push("/login");
        })
        .catch((_) => {});
    },
    handleSearchPatient() {
      if (this.keywords.length > 0) {
        this.isShow = true;
        this.loading = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          SearchPatient({
            queryMessage: this.keywords,
          })
            .then(({ data }) => {
              this.searchArray = data;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }, 800);
        window.addEventListener("click", (event) => {
          const searchPop = document.querySelector(".search-result");
          if (searchPop) {
            if (
              event.target !== searchPop &&
              !searchPop.contains(event.target)
            ) {
              this.isShow = false;
            }
          }
        });
      } else {
        clearTimeout(this.timer);
        this.searchArray = [];
        this.isShow = false;
        this.loading = false;
      }
    },

    handleChangeMenu(path) {
      this.path = path;
      this.$router.push(path);
    },

    async handleChangeStore(officeId) {
      console.log(officeId);
      if (this.phoneFlag == 1) {
        const { data } = await reqGetLastCnoByUserId();
        // 登录坐席是空闲状态
        if (data.seatStatus === "1" && data.deviceStatus === "0") {
          await reqCellLogout(data.seatNum);
          this.useLoginOffice(officeId);
        }
        // 登录坐席忙线
        else if (data.seatStatus === "1" && data.deviceStatus !== "0") {
          Message.error("当前通话页面未关闭，无法切换诊所");
        }
        // 登录坐席事离线状态
        else {
          this.officeId = officeId;
          this.useLoginOffice(officeId);
        }
      } else {
        this.officeId = officeId;
        this.useLoginOffice(officeId);
      }
    },
    useLoginOffice(officeId) {
      LoginOffice({ lastOfficeId: officeId })
        .then(() => {
          this.user.officeId = officeId;
          this.user.qno = this.storeArray.filter(
            (i) => i.id == officeId
          )[0].qno;
          sessionStorage.setItem("officeChanged", true);
          const ljOfficeId = this.storeArray.find(
            (item) => item.id == officeId
          ).ljOfficeId;
          // 正畸预约列表需要ljOfficeId
          this.user.ljOfficeId = ljOfficeId;
          sessionStorage.officeChanged, true;
          sessionStorage.jc_odos_user = JSON.stringify(this.user);
          location.reload();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    LinkTo(patient_id) {
      this.keywords = "";
      this.isShow = false;
      this.searchArray = [];
      this.$router.push("/odos");
      this.$router.push(`/odos?patientid=${patient_id}&appid=0`);
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        location.reload();
      },
    },
  },
};
</script>
<script setup>
(function () {
  function __canvasWM({
    container = document.body,
    width = "300px",
    height = "200px",
    textAlign = "center",
    textBaseline = "middle",
    font = "12px Microsoft Yahei",
    fillStyle = "rgba(184, 184, 184, 0.2)",
    content = "水印",
    rotate = "45",
    zIndex = 10000,
  } = {}) {
    const args = arguments[0];
    const canvas = document.createElement("canvas");

    canvas.setAttribute("width", width);
    canvas.setAttribute("height", height);
    const ctx = canvas.getContext("2d");

    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.font = font;
    ctx.fillStyle = fillStyle;
    ctx.rotate((Math.PI / 180) * rotate);
    ctx.fillText(content, parseFloat(width) / 2, parseFloat(height) / 2);

    const base64Url = canvas.toDataURL();
    const __wm = document.querySelector(".__wm");

    const watermarkDiv = __wm || document.createElement("div");
    const styleStr = `
                  position:fixed;
                  top:0;
                  left:0;
                  bottom:0;
                  right:0;
                  width:100%;
                  height:100%;
                  z-index:${zIndex};
                  pointer-events:none;
                  background-repeat:repeat;
                  background-image:url('${base64Url}')`;

    watermarkDiv.setAttribute("style", styleStr);
    watermarkDiv.classList.add("__wm");

    if (!__wm) {
      container.style.position = "relative";
      container.insertBefore(watermarkDiv, container.firstChild);
    }

    const MutationObserver =
      window.MutationObserver || window.WebKitMutationObserver;
    if (MutationObserver) {
      let mo = new MutationObserver(function () {
        const __wm = document.querySelector(".__wm");
        // 只在__wm元素变动才重新调用 __canvasWM
        if ((__wm && __wm.getAttribute("style") !== styleStr) || !__wm) {
          // 避免一直触发
          mo.disconnect();
          mo = null;
          __canvasWM(JSON.parse(JSON.stringify(args)));
        }
      });

      mo.observe(container, {
        attributes: true,
        subtree: true,
        childList: true,
      });
    }
  }

  if (typeof module != "undefined" && module.exports) {
    //CMD
    module.exports = __canvasWM;
  } else if (typeof define == "function" && define.amd) {
    // AMD
    define(function () {
      return __canvasWM;
    });
  } else {
    window.__canvasWM = __canvasWM;
  }

  // 调用
  __canvasWM({
    content:
      sessionStorage.getItem("jcUserName") +
      JSON.parse(sessionStorage.getItem("jc_odos_user")).mobile.slice(7),
  });
})();
</script>
<style lang="scss" scoped>
.main-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #e5e6eb;
    padding: 0 20px;

    .logo {
      width: 168px;
      height: 80px;
      background: url("../assets/logo-footer.png") no-repeat left;
      background-size: 168px auto;
    }
    .call-btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eaf0fc;
      cursor: pointer;
      margin-right: 8px;
      .icon {
        width: 20px;
        height: 20px;
        background: url("../assets/Internet-phone/call.svg") no-repeat;
      }
      &:active {
        background: #91b3f9;
      }
    }

    .tools {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: auto;
      padding-right: 12px;

      .addBtn {
        display: flex;
        gap: 9px;
        border-radius: 8px;
        padding: 10px 16px;
      }

      .search-box {
        width: 358px;
        height: 40px;
        border-radius: 8px;
        background-color: #f4f4f5;
        margin-left: 12px;
        display: flex;
        align-items: center;
        overflow: hidden;

        .search-icon {
          width: 20px;
          height: 20px;
          background: url("../assets/search.png") no-repeat;
          margin-left: 14px;
        }

        .input-box {
          outline: none;
          border: none;
          flex: 1;
          width: 1%;
          height: 22px;
          background-color: transparent;
          margin-left: 16px;
          // border-left: solid 2px #ef8329;
          padding: 0 4px;
        }
      }

      .search-result {
        width: 363px;
        max-height: 470px;
        background-color: #fff;
        padding: 0 16px;
        position: absolute;
        right: 365px;
        top: 45px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
        overflow: hidden;
        overflow-y: auto;
        z-index: 10;

        .item-box {
          border-bottom: solid 1px #f0f0f0;
          display: flex;
          padding: 16px 0;

          .box-avatar {
            width: 40px;
            height: 40px;
          }

          .info {
            flex: 1;
            width: 1%;
            margin-left: 16px;

            .name {
              line-height: 24px;
              color: #3d3d3d;
              font-size: 16px;
            }

            .no {
              line-height: 24px;
              margin-top: 2px;
            }

            .birthday {
              height: 24px;
              display: flex;
              align-items: center;
              margin-top: 2px;
            }

            .doctor {
              line-height: 24px;
              margin-top: 2px;
            }

            .bar {
              height: 24px;
              display: flex;
              align-items: center;
              margin-top: 2px;

              .store {
                color: #2e6ce4;
              }
            }
          }

          .btn-panel {
            .btn {
              width: 108px;
              height: 32px;
              border-radius: 8px;
              border: solid 1px #dedfe0;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 10px;
              position: relative;
              cursor: pointer;

              &.disabled {
                background-color: #f5f7fa;
                color: #c0c4cc;
                border-color: #dcdfe6;
                cursor: no-drop;
              }

              &:last-child {
                margin-bottom: 0;
              }

              &::before {
                position: absolute;
                left: 2px;
                top: 2px;
                font-size: 12px;
              }
            }
          }

          &:last-child {
            border-bottom-width: 0;
          }
        }
      }

      .refresh {
        margin-left: 20px;
      }

      .help {
        margin-left: 20px;
      }

      .notice {
        width: 40px;
        height: 80px;
        background: url("../assets/notification.png") no-repeat center;
        margin-left: 10px;
        cursor: pointer;
      }

      .office {
        width: 160px;
        margin: 0 20px;

        :deep(input) {
          background-color: #f2f3f5;
          &:hover {
            background-color: #e6e8eb;
          }
        }

        /* 选择器右侧图标样式  \e790上箭头图标 \e78f下箭头图标 */
        :deep(.el-icon-arrow-up:before) {
          content: "\e78f";
          color: #4e5969;
        }

        :deep(.el-select-dropdown__list .selected) {
          color: #2e6ce4;
        }

        :deep(.el-input__inner) {
          border-radius: 8px;
          border: none;
          color: #1d2129;
          font-weight: 500;

          &:focus {
            background-color: #e6e8eb;
          }
        }
      }

      .user {
        height: 44px;
        display: flex;
        align-items: center;
        padding-right: 16px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background-color: #f2f3f5;
        }

        .avatar {
          width: 36px;
          height: 36px;
          margin: 0 16px 0 8px;
        }

        .nick {
          display: block;
          font-size: 16px;
          margin-right: 16px;
        }
      }

      .userBox {
        height: 44px;
        background: #e6e8eb;
      }

      .userCenter {
        width: 140px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #e5e6eb;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
        position: absolute;
        right: 52px;
        top: 50px;
        display: flex;
        flex-direction: column;
        padding: 2px 0;

        .userItem {
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 0 20px;
          cursor: pointer;

          &:hover {
            background-color: #e6e8eb;
          }
        }
      }
    }
  }

  .main {
    display: flex;
    flex: 1;

    .aside {
      background-color: #fff;
      position: relative;
      padding-left: 10px;
      padding-right: 4px;
      margin-top: 20px;
      overflow-y: scroll;
      height: calc(100vh - 125px);

      .el-menu {
        transition: all 10ms;
      }

      .el-menu--collapse {
        width: 50px;
      }

      .el-menu:not(.el-menu--collapse) {
        width: 144px;
        height: 100%;
      }

      .el-menu-item {
        min-width: 100%;
        padding-left: 15px !important;

        &.active {
          height: 50px;
        }
      }

      .el-menu-item,
      :deep(.el-submenu__title) {
        display: flex;
        align-items: center;
        padding: 0 0;
        padding-left: 15px !important;
        border-radius: 12px;
        margin: 4px 0;
        height: 50px;

        &:hover {
          background-color: #e6e8eb;
        }

        .name {
          margin-left: 6px;
        }

        .icon {
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: left;

          &.files {
            background-image: url("@/assets/menu/files.png");
          }

          &.case {
            background-image: url("@/assets/menu/case.png");
          }

          &.school {
            background-image: url("@/assets/menu/school.png");
          }

          &.market {
            background-image: url("@/assets/menu/market.svg");
          }

          &.order {
            background-image: url("@/assets/menu/order.png");
          }

          &.patient {
            background-image: url("@/assets/menu/user.png");
          }

          &.check {
            background-image: url("@/assets/menu/check.svg");
          }

          &.nkj {
            background-image: url("@/assets/menu/nkj.svg");
          }

          &.setting {
            background-image: url("@/assets/menu/setting.svg");
          }

          &.orth {
            background-image: url("@/assets/menu/orth.svg");
          }

          &.treat {
            background-image: url("@/assets/menu/treat.svg");
          }

          &.leads {
            background-image: url("@/assets/menu/leads.svg");
          }
        }

        .name {
          font-weight: bold;
        }

        &.is-active {
          color: #fff;
          background-color: #2e6ce4;

          .icon {
            &.files {
              background-image: url("@/assets/menu/files-active.png");
            }

            &.case {
              background-image: url("@/assets/menu/case-active.png");
            }

            &.school {
              background-image: url("@/assets/menu/school-active.png");
            }

            &.order {
              background-image: url("@/assets/menu/order-active.png");
            }

            &.patient {
              background-image: url("@/assets/menu/user-active.png");
            }

            &.nkj {
              background-image: url("@/assets/menu/nkj-active.svg");
            }

            &.check {
              background-image: url("@/assets/menu/check-active.svg");
            }

            &.orth {
              background-image: url("@/assets/menu/orth-active.svg");
            }

            &.treat {
              background-image: url("@/assets/menu/treat-active.svg");
            }

            &.leads {
              background-image: url("@/assets/menu/leads-active.svg");
            }
          }
        }
      }

      :deep(.el-submenu) {
        &.is-active {
          .el-submenu__title {
            color: #2e6ce4;
          }

          .icon {
            &.files {
              background-image: url("@/assets/menu/files-active.png");
            }

            &.leads {
              background-image: url("@/assets/menu/leads-active.svg");
            }

            &.market {
              background-image: url("@/assets/menu/market-active.svg");
            }

            &.setting {
              background-image: url("@/assets/menu/setting-active.svg");
            }
          }

          .el-icon-arrow-down {
            color: #2e6ce4;
          }
        }

        .el-menu-item {
          &.is-active {
            background-color: #2e6ce4;
            color: #fff;
          }
        }
      }

      .item-box {
        width: 168px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 12px;
        font-size: 18px;
        color: #131414;
        cursor: pointer;

        &::before {
          width: 18px;
          height: 20px;
          background-image: url("@/assets/menu/ico-book.svg");
          background-repeat: no-repeat;
          background-size: 18px 20px;
          margin-right: 7px;
          content: "";
          margin-left: 15px;
        }

        &.active {
          background-color: #2e6ce4;
          border-radius: 12px;
          color: #fff;

          &::before {
            background-image: url("@/assets/menu/ico-book-active.svg");
          }

          &::after {
            width: 10px;
            height: 7px;
            background: url("../assets/arrow-up-white.svg") no-repeat;
            background-size: 10px 7px;
            content: "";
            margin-left: auto;
            margin-right: 15px;
          }
        }
      }

      .collapse {
        position: fixed;
        left: 116px;
        bottom: 18px;
      }

      .expend {
        left: 26px;
        transform: rotateY(180deg);
        transition: all 0.5s;
      }
    }
  }

  .app-container {
    width: 100%;
    height: calc(100vh - 80px);
    overflow: hidden;
    overflow-y: auto;
    background-color: #f2f3f5;
  }
}

.trans {
  transform: rotate(180deg);
  transition: all 0.5s;
}
</style>

<style>
/* 菜单右侧箭头 */
.el-icon-arrow-down:before {
  content: "\E790" !important;
}

/* 菜单弹出层 */
.el-menu--popup-right-start {
  border-radius: 12px;
}
</style>
